
<template>
    <Layout style="height: 100vh;" class="main">
        <Sider ref="siderRef" hide-trigger collapsible :collapsed-width="0"  v-model="isCollapsed" >
            <Menu active-name="1-2" theme="dark" width="auto" :class="menuitemClasses" style="margin-top: 100px;">
                <MenuSub :menus="menuList" ></MenuSub>
            </Menu>
        </Sider>
        <Layout style="height: 100%; z-index: 100;">
            <Header class="header-con">
                <div style="display: flex;align-items: center; justify-content: space-between;">
                    <div style="display: flex; align-items: center;">
                        <Icon @click="collapsedSider" :class="rotateIcon" type="md-menu" size="24"/>
                        <p style="margin-left: 25px;font-size: 15px; font-weight: bold;">黑牛货运</p>
                    </div>
                    <Infos :userName="userName" :avatarUrl="avaURL" :countMsg="countMessage" @event="userInfoEvent"></Infos>
                </div>
            </Header>
            <Layout>
                <Breadcrumb style="margin: 15px 15px">
                    <BreadcrumbItem>Home</BreadcrumbItem>
                    <BreadcrumbItem>Components</BreadcrumbItem>
                    <BreadcrumbItem>Layout</BreadcrumbItem>
                </Breadcrumb>
                <Content class="content-wrapper">
                    <router-view v-slot="{Component}">
                        <KeepAlive>
                            <component :is="Component" :key="$route.name" v-if="$route.meta.isCache"/>
                        </KeepAlive>
                        <component :is="Component" :key="$route.name" v-if="!$route.meta.isCache"/>
                    </router-view>
                </Content>
            </Layout>

        </Layout>
    </Layout>


</template>

<script setup>

import './main.less'

import { computed,ref,onMounted } from "vue";
import { useStore} from 'vuex'
import MenuSub from '@c/MenuNav/MenuSub.vue'
import {useRoute, useRouter} from 'vue-router'
import {setToken} from '@/utils/auth'
import Infos from './main-components/infos'

//import SideMenu from "./components/side-menu"
const router=useRouter()
const route=useRoute()
const store=useStore();

const isCollapsed=ref(false)

const menuList=computed(()=>store.getters.Menus)

const siderRef=ref(null)

const userName=ref("test")
const avaURL=ref('')
const countMessage=ref(0)

//=================================================================
onMounted(()=>{
    let routes= router.getRoutes()
    let fpath= route.meta.title
    console.log(JSON.stringify(fpath))

    //console.log(store.getters.UserInfo)
    userName.value=store.getters.UserInfo?.nickName
    avaURL.value=store.getters.UserInfo?.headImageUrl
})
//=================================================================
const rotateIcon=computed(()=>{
    return [
        'menu-icon',
        isCollapsed.value?'rotate-icon':''
    ];
})

const menuitemClasses=computed(()=>{
    return [
        'menu-item',
        isCollapsed.value?'collapsed-menu':''
    ];
})

const  collapsedSider = () => {
    siderRef.value.toggleCollapse()
}
//=====================================================================
function userInfoEvent(param)
{
    //console.log(param)
    if(param.EventName=="exit")
    {
        store.commit('setUser',null)
        setToken(null)
        router.push('login')
    }
}


</script>

<style lang="less" scoped>

.menu-icon{
    transition: all .3s;
}
.rotate-icon{
    transform: rotate(-90deg);
}
</style>
