import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'
import routerMaps from './routerMaps'
import {isUserLogin} from '../utils/auth'
import { useStore } from 'vuex'

const router = createRouter({
  history: createWebHistory(),
  routes: routerMaps
})

//添加一个导航钩子，会在每次导航之后被执行，返回一个用来移除该钩子的函数
router.afterEach((to,from,failure)=>{

})

//通过调用 history.back() 在可能的情况下在历史中后退。相当于 router.go(-1)。
//router.back();

//每次导航之前被调用
router.beforeEach((to,from,next)=>{
  //to.meta.keepAlive=true;
  document.title=process.env.VUE_APP_TITLE+"-"+to.meta.title

  let store=useStore()
  let ui= store.getters.UserInfo

  if(to.matched.some(record=>record.meta.requireAuth)){
    if(!isUserLogin())
      {
        next({path:'/',query:{redirect:to.fullPath}});
        //next({path:'/',params:null});
      }
      else{
        if(to.matched.some(record=>record.meta.roles))
        {
          
          if(to.meta.roles.indexOf(ui.roleName)!==-1)
              next()
          else
            next({path:'/about'})
        }
        else
          next();
      }
  }
  else{
    next()
  }

  //if(to.name!==from.name)
  //  localStorage.setItem("prevPath",from)

  //next()
})

//添加一个导航守卫，它会在导航将要被解析之前被执行。此时所有组件都已经获取完毕，且其它导航守卫也都已经完成调用。返回一个用来移除该守卫的函数

router.beforeResolve(to=>{
  
})

export default router
