
import routerMaps from "../../router/routerMaps"
import createPersistedState from 'vuex-persistedstate'


export default{
  //该属性用来存储数据和存储状态。它的返回值会根据它的依赖被缓存起来，且只有当它的依赖值发生了改变，才会被被重新计算
  state: {
    menus: routerMaps,
    userInfo: null
  },
  //该属性类似于vue.js的计算属性。可以对State进行计算操作，虽然组件内也可以进行计算操作，但是Getter可以在多组件之间复用，而且Getter的返回值会根据它的依赖被缓存起来，只有Getter中的依赖值（state中的某个需要派生状态的值）发生改变的时候才会被重新计算。
  getters: {
    Menus: state=>state.menus,
    UserInfo: state=>state.userInfo
  },
  //该属性用来更改state 的状态。每个mutation都有一个字符串类型的事件类型和一个回调函数，在函数中改变state的值。执行这个函数，需要执行一个相应的调用方法：store.commit。
  mutations: {
    setUser(state,userInfo){
      state.userInfo=userInfo
    },
    setToken(state,token){
      state.userInfo.token=token
    }
  },
  //该属性用来提交mutations，在action中可以执行store.commit，action没有异步操作。在页面中使用这个action，可以执行store.dispatch。
  actions: {
    getToken({getters}){
      return getters.UserInfo.token
    },
    setToken({commit},token){
      commit('setToken',token)
    },
    isUserLogin({getters}){
      const token=getters.UserInfo.token

      return !!token
    }
  },

  plugins:[
    createPersistedState()
  ],

  //该属性可以将store分割程不同的模块。每个模块有自己的state，mutations，action，getters。使结构变得更加清晰，方便管理。
  modules: {
  }
}